
import { initializeApp } from "firebase/app";
import {getFirestore} from 'firebase/firestore'
import {getDatabase} from 'firebase/database'
// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration

const firebaseConfig = {

  apiKey: process.env.REACT_APP_API_KEY,

  authDomain: process.env.REACT_APP_AUTH_DOMAIN,

  projectId: process.env.REACT_APP_PROJECT_ID,

  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,

  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,

  appId: process.env.REACT_APP_APP_ID,

  databaseURL: process.env.REACT_APP_DATABASE_URL,

};

// Initialize Firebase

const app = initializeApp(firebaseConfig);
export const db = getFirestore()
export const database = getDatabase(app);